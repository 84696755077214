<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-overlay
          :show="loading"
          rounded="sm"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
          <div
            class="cursor-pointer"
            @click="$router.push('/app/customers')"
          >
            <statistic-card
              :statistic="dashboard.counters.customers"
              icon="UsersIcon"
              statistic-title="Müşteri Kartları"
              color="primary"
            />
          </div>
        </b-overlay>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-overlay
          :show="loading"
          rounded="sm"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
          <div
            class="cursor-pointer"
            @click="$router.push('/app/interviews')"
          >
            <statistic-card
              :statistic="dashboard.counters.interviews"
              icon="MessageCircleIcon"
              statistic-title="Görüşmeler"
              color="success"
            />
          </div>
        </b-overlay>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-overlay
          :show="loading"
          rounded="sm"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
          <div
            class="cursor-pointer"
            @click="$router.push('/app/offers')"
          >
            <statistic-card
              :statistic="dashboard.counters.offers"
              icon="FileTextIcon"
              statistic-title="Teklifler"
              color="info"
            />
          </div>
        </b-overlay>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-overlay
          :show="loading"
          rounded="sm"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
          <div
            class="cursor-pointer"
            @click="$router.push('/app/projects')"
          >
            <statistic-card
              :statistic="dashboard.counters.projects"
              icon="BookIcon"
              statistic-title="Projeler"
              color="warning"
            />
          </div>
        </b-overlay>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-overlay
          :show="loading"
          rounded="sm"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
          <div
            class="cursor-pointer"
            @click="$router.push('/app/projects/requests')"
          >
            <statistic-card
              :statistic="dashboard.counters.projectsRequests"
              icon="FileTextIcon"
              statistic-title="Proje Talepleri"
              color="info"
            />
          </div>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row
      v-if="!loading"
      class="match-height"
    >
      <b-col
        cols="12"
        md="12"
      >
        <projects
          v-if="dashboard.projects.length > 0"
          :data-list="dashboard.projects"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="!loading"
      class="match-height"
    >
      <b-col
        v-if="dashboard.interviews.length > 0"
        cols="12"
        md="6"
      >
        <interviews
          v-if="dashboard.interviews.length > 0"
          :data-list="dashboard.interviews"
        />
      </b-col>
      <b-col
        v-if="dashboard.offers.length > 0"
        cols="12"
        :md="dashboard.interviews.length > 0 ? 6:12 "
      >
        <offers
          v-if="dashboard.offers.length > 0"
          :data-list="dashboard.offers"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="!loading"
      class="match-height"
    >
      <b-col
        cols="12"
        md="6"
      >
        <dashboard-stocks
          v-if="dashboard.stocks.length > 0"
          :data-list="dashboard.stocks"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCol, BRow, BOverlay,
} from 'bootstrap-vue'
import StatisticCard from '@/views/Dashboard/components/StatisticCard.vue'
import Interviews from '@/views/Dashboard/components/Interviews.vue'
import Offers from '@/views/Dashboard/components/Offers.vue'
import Projects from '@/views/Dashboard/components/Projects.vue'
import DashboardStocks from '@/views/Dashboard/components/Stocks.vue'

export default {
  name: 'Manager',
  components: {
    DashboardStocks,
    BOverlay,
    BRow,
    BCol,
    StatisticCard,
    Interviews,
    Offers,
    Projects,
  },
  data() {
    return {
      variant: 'transparent',
      opacity: 0.90,
      blur: '2px',
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    dashboard() {
      return this.$store.getters['dashboard/dashboard']
    },
    loading() {
      return this.$store.getters['dashboard/loading']
    },
    filters() {
      return this.$store.getters['dashboard/filters']
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(val) {
        if (val.sdate || val.edate) {
          this.getDashboard()
        }
      },
    },
  },
  created() {
    this.getDashboard()
  },
  methods: {
    getDashboard() {
      this.$store.dispatch('dashboard/getData', this.filters)
    },
  },
}
</script>
