<template>
  <b-card
    no-body
  >
    <b-card-header>

      <h4 class="mb-0">
        Kritik Stoklar
      </h4>
      <b-card-text class="font-medium-5 mb-0">
        <feather-icon
          v-b-tooltip.hover.v-primary.left
          title="Kritik duruma düşen azalan stoklar listelenir."
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text>
    </b-card-header>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="tableList"
      hover
      small
    >
      <template #cell(title)="data">
        <div
          class="d-flex align-items-center cursor-pointer"
          @click="$router.push('/app/stocks/view/' + data.item.id)"
        >
          <div>
            <div>
              <small class="text-primary">{{ data.item.scode }}</small>
            </div>
            <div>
              {{ data.item.title }}
            </div>
            <div class="font-small-2 text-warning">
              {{ data.item.stock_groups }}
            </div>
          </div>
        </div>
      </template>
      <template #cell(amount)="data">
        <div
          class="cursor-pointer"
          @click="$router.push('/app/stocks/view/' + data.item.id)"
        >
          <div>
            <span :class="data.item.amount < 1 ? 'text-danger' : 'text-warning'">{{ data.item.amount |toNumber }}</span> / <b>{{ data.item.min_amount | toNumber }}</b>
          </div>
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-button
        variant="primary"
        block
        size="sm"
        @click="stocksMore ^= true"
      >
        {{ stocksMore ? 'Tamamını göster' : 'Daha az göster' }}
      </b-button>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardText, BTable, VBTooltip, BCardFooter, BButton,
} from 'bootstrap-vue'

export default {
  name: 'DashboardStocks',
  components: {
    BCard,
    BCardHeader,
    BCardText,
    BTable,
    BCardFooter,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      stockLimit: 10,
      stocksMore: true,
      fields: [
        {
          key: 'title',
          label: 'Stok',
          tdClass: 'font-small-2',
        },
        {
          key: 'amount',
          label: 'Adet',
          tdClass: 'font-small-2',
        },
      ],
    }
  },
  computed: {
    tableList() {
      let data = {}
      if (this.stocksMore) {
        data = this.dataList.splice(0, 10)
      } else {
        data = this.dataList
      }
      return data
    },
  },
}
</script>
